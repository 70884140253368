<script setup lang="ts">
const route = useRoute();
</script>

<template>
  <MoleculesMenuDesktop
    :class="[
      'hidden lg:flex',
      route.path === '/entrar/cadastrar-telefone' ? 'blur-[2px]' : '',
    ]"
  />
</template>

<style scoped lang="scss"></style>

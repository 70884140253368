import {
  type ExcelColumnError,
  type ExcelCellsWithError,
  type ExcelHeaderErrors,
  type MaximumColumnSize,
} from "~/api/operational";

/**
 * Tipo que representa erros de colunas divergentes no Excel.
 */
export type DivergentColumnsError = {
  correctOrder: string[]; // Ordem correta das colunas
  notAllowed?: string[]; // Colunas não permitidas
  missing?: string[]; // Colunas faltantes
};

/**
 * Tipo que representa diferentes tipos de erros no Excel.
 */
export type ExcelError =
  | {
      type: "generic"; // Erro genérico
    }
  | {
      type: "empty"; // Erro de célula vazia
    }
  | {
      type: "divergent_columns"; // Erro de colunas divergentes
      divergentColumns: DivergentColumnsError;
    }
  | {
      type: "cells_with_error"; // Erro de células com erro
      cellsWithError?: string[];
    };

/**
 * Utilitários para manipulação e análise de erros em arquivos Excel.
 */
export const excelUtils = {
  /**
   * Transforma um agrupamento de células com erro em uma array de strings com os erros já convertidos.
   * @param data_errors - Objeto contendo os erros das células.
   * @returns Array de mensagens de erro.
   */
  parseCellsWithError(data_errors: ExcelCellsWithError) {
    if (data_errors) {
      // Definição das mensagens de erro para cada tipo de erro nas colunas
      const columnsWithError = [
        {
          message: "Dados não numéricos",
          errors: data_errors.columns_not_float,
          column_name: "columns_not_float",
        },
        {
          message: "Formato de data inválido",
          errors: data_errors.invalid_date_format,
          column_name: "invalid_date_format",
        },
        {
          message: "Dados que não são números inteiros",
          errors: data_errors.columns_not_integer,
          column_name: "columns_not_integer",
        },
        {
          message: 'Valores diferentes de "si" ou "no"',
          errors: data_errors.columns_not_si_or_no,
          column_name: "columns_not_si_or_no",
        },
        {
          message: "Formato de e-mail inválido",
          errors: data_errors.invalid_email_format,
          column_name: "invalid_email_format",
        },
        {
          message: "Subgrupo não encontrado",
          errors: data_errors.subgroups_not_found,
          column_name: "subgroups_not_found",
        },
        {
          message: "Célula vazia",
          errors: data_errors.empty_cells,
          column_name: "empty_cells",
        },
        {
          message: "Contrato duplicado no sistema",
          errors: data_errors.contract_already_exists,
          column_name: "contract_already_exists",
        },
        {
          message: "Quantidade de caracteres acima do permitido",
          errors: data_errors.maximum_column_size_exceeded,
          column_name: "maximum_column_size_exceeded",
        },
        {
          message: "Valor abaixo do mínimo esperado",
          errors: data_errors.column_value_too_low,
          column_name: "column_value_too_low",
        },
      ];

      /**
       * Função auxiliar para converter erros em mensagens de erro.
       * @param message - Mensagem base do erro.
       * @param columns - Colunas com erro.
       * @param columnName - Nome da coluna com erro.
       * @returns Array de mensagens de erro.
       */
      const parseErrorMessages = (
        message: string,
        columns: ExcelColumnError[] | MaximumColumnSize,
        columnName: string
      ): string[] => {
        // Tratamento específico para contrato duplicado no sistema
        if (columnName === "contract_already_exists") {
          const allLinesWithProblem = columns.map((col) => col.line);
          return [
            `${message}: linha${
              allLinesWithProblem.length > 1 ? "s" : ""
            } ${allLinesWithProblem.join(",")}`,
          ];
        }
        // Tratamento específico para formato de data inválido
        if (columnName === "invalid_date_format") {
          return columns.map((col) => {
            const colsLabel =
              col.columns.length > 1
                ? `colunas ${col.columns.map((c) => `"${c}"`).join(", ")}`
                : `coluna "${col.columns[0]}"`;
            return `${message}: linha ${col.line}, ${colsLabel}`;
          });
        }
        // Tratamento específico para quantidade de caracteres acima do permitido
        if (columnName === "maximum_column_size_exceeded") {
          return (columns as unknown as MaximumColumnSize).map((col) => {
            const colsLabel =
              col.columns.length > 1
                ? `colunas: ${col.columns
                    .map(
                      (c) => `"${c.column}" (${c.character_limit} caracteres)`
                    )
                    .join(", ")}`
                : `coluna "${col.columns[0].column}" (${col.columns[0].character_limit} caracteres)`;
            return `${message}: linha ${col.line}, ${colsLabel}`;
          });
        }
        // Tratamento padrão para outros tipos de erro
        return columns.map(
          (col) => `${message}: linha ${col.line}, coluna "${col.columns[0]}"`
        );
      };

      let allErrorMessages: string[] = [];

      // Itera sobre cada tipo de erro e gera as mensagens de erro correspondentes
      for (const column of columnsWithError) {
        if (column.errors && column.errors.length) {
          allErrorMessages = [
            ...allErrorMessages,
            ...parseErrorMessages(
              column.message,
              column.errors,
              column.column_name
            ),
          ];
        }
      }

      // Tratamento para linhas duplicadas
      if (data_errors.duplicate_line_in_excel) {
        for (const duplicatedLines of data_errors.duplicate_line_in_excel) {
          for (const duplicateInfos of duplicatedLines.reverse()) {
            const allLines = duplicateInfos.duplicates.map((d) => d.line);
            if (!allLines.length) continue;
            let lines = "";
            if (allLines.length === 1) {
              lines = `linha ${allLines[0]}`;
            } else if (allLines.length === 2) {
              lines = `linhas ${allLines[0]} e ${allLines[1]}`;
            } else if (allLines.length > 2) {
              lines = `linhas ${allLines
                .slice(0, allLines.length - 2)
                .join(", ")} e ${allLines[allLines.length - 1]}`;
            }
            allErrorMessages.push(`Contratos duplicados: ${lines}`);
          }
        }
      }

      return allErrorMessages;
    }
  },

  /**
   * Converte erros de cabeçalho em um objeto DivergentColumnsError.
   * @param headers - Objeto contendo os erros de cabeçalho.
   * @returns Objeto DivergentColumnsError.
   */
  parseDivergentCols(headers: ExcelHeaderErrors): DivergentColumnsError {
    return {
      correctOrder: headers[
        "As colunas devem ser ordenadas da seguinte maneira:"
      ]
        ? headers["As colunas devem ser ordenadas da seguinte maneira:"]
            .split("|")
            .filter((val) => val !== "" && !!val)
        : [],
      missing: headers["Coluna(s) que faltaram"]
        ? (JSON.parse(headers["Coluna(s) que faltaram"]) as string[])
        : [],
      notAllowed: headers["Coluna(s) não permitidas ou com nomenclatura errada"]
        ? (JSON.parse(
            headers["Coluna(s) não permitidas ou com nomenclatura errada"]
          ) as string[])
        : [],
    };
  },
};

<script setup lang="ts">
import type { MenuItem } from "~/constants/menu";

const props = defineProps<{ item: MenuItem; onlyIcon?: boolean }>();
const route = useRoute();

const active = computed(() => {
  if (props.item.to === "/" && route.path === "/") return true;
  if (props.item.to !== "/" && route.path.startsWith(props.item.to))
    return true;
  return false;
});
</script>

<template>
  <AtomsTooltip
    :title="item.title"
    side="right"
    :disabled="!onlyIcon"
    :trigger-full="!onlyIcon"
  >
    <button
      class="menu-desktop-item"
      :class="{ active }"
      type="button"
      @click="navigateTo(item.to)"
    >
      <AtomsIcon
        v-if="item.icon && typeof item.icon === 'string'"
        :name="item.icon"
        :size="24"
        :class="active ? 'text-primary' : 'text-gray-dark'"
      />
      <component
        v-else-if="item.customIcon"
        :is="item.customIcon"
        class="w-6 h-6"
        :class="active ? 'text-primary' : 'text-gray-dark'"
      />
      <span v-if="!onlyIcon">{{ item.title }}</span>
    </button>
  </AtomsTooltip>
</template>

<style scoped lang="scss">
.menu-desktop-item {
  @apply w-full bg-transparent border-none flex items-center justify-start gap-[10px] p-[10px] rounded-[10px] transition text-left;

  span {
    @apply text-[16px] leading-[20px];
  }

  &.active {
    @apply bg-[rgba(0,102,255,0.1)];
    span {
      @apply text-primary;
    }
  }

  &:not(.active) {
    span {
      @apply text-gray-dark;
    }
  }
}
</style>

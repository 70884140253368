<template>
  <main>
    <div
      class="panel"
      :style="{
        paddingLeft: `${menuWidth}px`,
      }"
    >
      <MoleculesMenu ref="menuElement" />
      <div class="panel__scroll-area" ref="scrollArea">
        <section
          class="panel__content"
          v-show="displayContent"
          v-if="showContent"
        >
          <slot />
        </section>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
const route = useRoute();
const queryClient = useQueryClient();

// Área do conteudo
const scrollArea = ref<HTMLDivElement>();
const menuWidth = ref(250);

// Define se o conteudo é renderizado ou não
const showContent = ref(true);

// Define se o conteudo é exibido ou não
const displayContent = ref(true);

// Reseta o scroll da div do conteudo
const resetScroll = () => {
  if (scrollArea.value)
    scrollArea.value.scrollTo({
      top: 0,
      behavior: "smooth",
    });
};

// Recarrega a página, forçando com que tudo carregue novamente
const refresh = () => {
  showContent.value = false;
  setTimeout(() => (showContent.value = true), 40);
};

// Pisca a página para dar um efeito de transição
const blink = () => {
  displayContent.value = false;
  setTimeout(() => (displayContent.value = true), 40);
};

// Pisca a página ao alterar a rota
watch(
  () => route.path,
  () => {
    blink();
  }
);

// Disponibiliza as funções para as páginas usarem
provide("layout", {
  refresh,
  resetScroll,
  blink,
  menuWidth,
});
</script>

<style scoped lang="scss">
.panel {
  @apply w-full h-[100dvh] max-h-[100dvh] overflow-auto flex relative;

  &__scroll-area {
    @apply h-full relative w-full;
  }

  &__content {
    @apply relative w-full min-h-full max-w-[1472px] mx-auto flex justify-center pt-[32px] px-10 2xl:px-6 lg:overflow-x-hidden 2xl:overflow-x-auto animate-fadeIn;

    @media (min-width: 1790px) {
      @apply px-0;
    }
  }
}
</style>

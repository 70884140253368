export enum UploadExcelMessages {
  EMPTY_SHEET = "A planilha do Excel está vazia.",
  DIVERGENT_COLS = "Algumas colunas estão divergentes.",
  CELLS_WITH_ERROR = "Algumas células no corpo do Excel estão com erros.",
}

export type ExcelColumnError<T = [string]> = {
  columns: T;
  line: number;
};

export type DuplicateLineInExcel = {
  duplicate_fields: Array<string | number>;
  duplicates: [
    { contract: Record<string, string | number | boolean>; line: number },
    { contract: Record<string, string | number | boolean>; line: number }
  ];
};
export type MaximumColumnSize = ExcelColumnError<
  { column: string; character_limit: number }[]
>[];
export interface ExcelCellsWithError {
  columns_not_float?: ExcelColumnError[];
  columns_not_integer?: ExcelColumnError[];
  columns_not_si_or_no?: ExcelColumnError[];
  empty_cells?: ExcelColumnError[];
  invalid_email_format?: ExcelColumnError[];
  subgroups_not_found?: ExcelColumnError[];
  contract_already_exists?: ExcelColumnError[];
  invalid_date_format?: ExcelColumnError[];
  maximum_column_size_exceeded?: MaximumColumnSize;
  duplicate_line_in_excel?: Array<DuplicateLineInExcel[]>;
  column_value_too_low?: ExcelColumnError[];
}

export interface ExcelHeaderErrors {
  "As colunas devem ser ordenadas da seguinte maneira:"?: string;
  "Coluna(s) não permitidas ou com nomenclatura errada"?: string;
  "Coluna(s) que faltaram"?: string;
}

export interface UploadExcelErrors {
  data_errors: ExcelCellsWithError;
  header_errors: ExcelHeaderErrors;
  message: UploadExcelMessages | string;
  success: boolean;
}

export const operationalApi = {
  async uploadExcel(excel: File) {
    const formData = new FormData();
    formData.append("excel", excel);

    return useNuxtApp().$api.post<{ token?: string }>(
      `/operator/contracts/read_contract_data_from_excel`,
      formData
    );
  },
};

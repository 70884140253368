import { UserRoles, type UserActions } from "~/types";
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import DocumentTextIcon from "@/assets/images/icons/document-text.svg";
import ConfigGearIcon from "@/assets/images/icons/configurations-gear.svg";
import {
  ChartBarIcon,
  OfficeBuildingIcon,
  CalculatorIcon,
  StarIcon,
} from "~/assets/images/icons";

export interface MenuItem {
  icon?: string;
  customIcon?:
    | FunctionalComponent<HTMLAttributes & VNodeProps, {}, any, {}>
    | string;
  to: string;
  title: string;
  actions?: UserActions[];
  roles?: UserRoles[];
}

export interface MenuGroup {
  title: string;
  items: MenuItem[];
  roles?: UserRoles[];
}

export const menuGroups: MenuGroup[] = [
  {
    title: "Administrativo",
    items: [
      {
        customIcon: ChartBarIcon,
        title: "Dashboard",
        to: "/",
        roles: [UserRoles.OPERATIONAL, UserRoles.ADMIN],
      },
      {
        customIcon: StarIcon,
        title: "Outros seguros",
        to: "/outros-seguros",
        roles: [UserRoles.SUPPLIER],
      },
      // {
      //   customIcon: HomeIcon,
      //   title: "Home",
      //   to: "/",
      //   roles: [UserRoles.SUPPLIER],
      // },
      {
        icon: "user-check",
        title: "Contratos",
        to: "/contratos",
        actions: Actions.GET_ALL_CONTRACTS,
      },
      {
        customIcon: OfficeBuildingIcon,
        title: "Empresas",
        to: "/empresas",
        actions: Actions.GET_ALL_COMPANIES,
      },
      {
        customIcon: DocumentTextIcon,
        title: "Relatórios",
        to: "/relatorios",
        roles: [UserRoles.OPERATIONAL, UserRoles.ADMIN],
      },
      {
        customIcon: ConfigGearIcon,
        title: "Configurações",
        to: "/configuracoes",
        roles: [UserRoles.ADMIN],
      },
      {
        customIcon: CalculatorIcon,
        title: "Simulador de cálculo",
        to: "/simulador-de-calculo",
        roles: [UserRoles.OPERATIONAL, UserRoles.ADMIN],
      },
    ],
  },
];
